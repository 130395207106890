import aasif from "../../../assets/images/faculty/aasif.jpg";
import aditi from "../../../assets/images/faculty/aditi.jpg";
import akshit from "../../../assets/images/faculty/akshit.jpeg";
import amandeepkaurpharma from "../../../assets/images/faculty/amandeepkaurpharma.jpeg";
import amankumar from "../../../assets/images/faculty/amankumar.png";
import amanpreet from "../../../assets/images/faculty/amanpreet.jpg";
import amansharma from "../../../assets/images/faculty/amansharma.jpg";
import anamika from "../../../assets/images/faculty/anamika.jpg";
import anchal from "../../../assets/images/faculty/anchal.jpg";
import anika from "../../../assets/images/faculty/anika.jpg";
import anterpreet from "../../../assets/images/faculty/anterpreet.jpg";
import arminderjit from "../../../assets/images/faculty/arminderjit.jpg";
import arshdeep from "../../../assets/images/faculty/arashdeep.jpg";
import arvind from "../../../assets/images/faculty/arvind.jpg";
import ashok from "../../../assets/images/faculty/ashok.jpg";
import avnneet from "../../../assets/images/faculty/avnneet.jpg";
import bandana from "../../../assets/images/faculty/bandana.jpg";
import bharat from "../../../assets/images/faculty/bharat.jpg";
import dhriti from "../../../assets/images/faculty/dhriti.jpg";
import dilrose from "../../../assets/images/faculty/dilrose.jpeg";
import dummy from "../../../assets/images/faculty/dummy.jpg";
import gagandeep from "../../../assets/images/faculty/gagan.jpg";
import geeta from "../../../assets/images/faculty/geeta.jpg";
import harbinder from "../../../assets/images/faculty/harbinder.jpeg";
import harpreet from "../../../assets/images/faculty/harpreet.jpg";
import inderpal from "../../../assets/images/faculty/inderpal.jpeg";
import jasmeen from "../../../assets/images/faculty/jasmeen.jpg";
import jps from "../../../assets/images/faculty/jps.jpg";
import kajal from "../../../assets/images/faculty/kajal.jpg";
import kapilkanwar from "../../../assets/images/faculty/kapilkanwar.jpg";
import kiran from "../../../assets/images/faculty/kiran.jpg";
import kritika from "../../../assets/images/faculty/kritika.jpg";
import kuljeet from "../../../assets/images/faculty/kuljeet.jpeg";
import kunal from "../../../assets/images/faculty/kunal1.jpeg";
import lakhvinder from "../../../assets/images/faculty/lakhvinder.jpeg";
import lovdeep from "../../../assets/images/faculty/lovdeep.jpg";
import managaldeep from "../../../assets/images/faculty/mangaldeep.jpg";
import manisha from "../../../assets/images/faculty/manisha.jpg";
import manjot from "../../../assets/images/faculty/manjot.jpg";
import minshu from "../../../assets/images/faculty/manishu.jpg";
import muzamil from "../../../assets/images/faculty/muzamil.jpg";
import nancy from "../../../assets/images/faculty/nancy.jpg";
import narinder from "../../../assets/images/faculty/narinder.jpg";
import narpat from "../../../assets/images/faculty/narpat.jpg";
import navjot from "../../../assets/images/faculty/navjot.jpg";
import navneet from "../../../assets/images/faculty/navneet.jpg";
import nidhi from "../../../assets/images/faculty/nidhi.jpg";
import nikita from "../../../assets/images/faculty/nikita.jpg";
import nitika from "../../../assets/images/faculty/nitikasehgal.jpg";
import pallvi from "../../../assets/images/faculty/pallavi.jpg";
import pankaj from "../../../assets/images/faculty/pankaj.jpg";
import parul from "../../../assets/images/faculty/parul.jpeg";
import pasricha from "../../../assets/images/faculty/pasricha.jpg";
import pawanpreet from "../../../assets/images/faculty/pawanpreet.jpg";
import poojadhand from "../../../assets/images/faculty/Pooja_dhand.jpg";
import poojarana from "../../../assets/images/faculty/poojarana.jpeg";
import poojasonik from "../../../assets/images/faculty/poojasonik.jpg";
import prabhjeet from "../../../assets/images/faculty/prabhjeet.jpg";
import preeti from "../../../assets/images/faculty/preeti.jpg";
import puneet from "../../../assets/images/faculty/puneet.jpg";
import raghav from "../../../assets/images/faculty/raghav.jpg";
import rajni from "../../../assets/images/faculty/rajni.jpg";
import rakesh from "../../../assets/images/faculty/rakesh.jpeg";
import rameshwer from "../../../assets/images/faculty/rameshwer.jpg";
import reena from "../../../assets/images/faculty/reena.jpg";
import reetika from "../../../assets/images/faculty/reetika.jpg";
import ruchi from "../../../assets/images/faculty/ruchi.jpg";
import rupinder from "../../../assets/images/faculty/rupinder.jpg";
import sandeep from "../../../assets/images/faculty/sandeep.jpg";
import sapna from "../../../assets/images/faculty/sapna.jpg";
import sarabjit from "../../../assets/images/faculty/sarabjit.jpg";
import sarbinder from "../../../assets/images/faculty/sarbrinder.jpeg";
import shahid from "../../../assets/images/faculty/shahid.jpg";
import shahidradio from "../../../assets/images/faculty/shahidradio.jpeg";
import shiksha from "../../../assets/images/faculty/shiksha.jpg";
import shina from "../../../assets/images/faculty/shaina.jpeg";
import shubham from "../../../assets/images/faculty/shubham.jpg";
import silky from "../../../assets/images/faculty/silky.jpg";
import simranjeet from "../../../assets/images/faculty/simranjeet.jpg";
import simranjeet1 from "../../../assets/images/faculty/simranjeet.jpeg";
import sonudua from "../../../assets/images/faculty/sonudua.jpg";
import sujata from "../../../assets/images/faculty/sujata.jpg";
import sumitkumar from "../../../assets/images/faculty/sumitkumar.jpg";
import tajamul from "../../../assets/images/faculty/tajamul.jpg";
import tarandeep from "../../../assets/images/faculty/tarandeep.jpeg";
import taranpreet from "../../../assets/images/faculty/taranpreet.jpg";
import tarundeep from "../../../assets/images/faculty/tarundeep.jpg";
import tarunjit from "../../../assets/images/faculty/tarunjit.jpg";
import varun from "../../../assets/images/faculty/varun.jpg";
import vikas from "../../../assets/images/faculty/vikasrattan.jpg";
import vinayshwetha from "../../../assets/images/faculty/vinayshwetha.jpeg";
import vishal from "../../../assets/images/faculty/vishal.jpg";

export const TeachingStaff = [
  {
    ProfilePic: inderpal,
    Letter: "I",
    Name: "Dr. Inderpal Singh    ",
    Role: "Dean Research & Examination,HoD Management",
  },
  {
    ProfilePic: poojadhand,
    Letter: "P",
    Name: "Dr. Pooja Dhand",
    Role: "Dean Academics & HoD CSE",
  },

  {
    ProfilePic: nidhi,
    Letter: "N",
    Name: "Dr. Nidhi Chopra",
    Role: "Dean Student Welfare & HoD-IT",
  },
  {
    ProfilePic: navneet,
    Letter: "N",
    Name: "Ms. Navneet Kaur",
    Role: "Assistant Professor  (HOD)",
  },

  {
    ProfilePic: dummy,
    Letter: "A",
    Name: "Dr. Abhishek Sachdeva",
    Role: "Asst. Director(Outreach Admission & Grievance)",
  },
  {
    ProfilePic: kunal,
    Letter: "A",
    Name: "Mr. Kunal Verma",
    Role: "Dy.Director T & P    ",
  },
  {
    ProfilePic: kapilkanwar,
    Letter: "T",
    Name: "Mr. Kapil Kanwar",
    Role: "Principal (School of Pharmacy)",
  },
  {
    ProfilePic: pawanpreet,
    Letter: "P",
    Name: "Dr. Pawanpreet Kaur    ",
    Role: "Assistant Professor (HOD)",
  },
  {
    ProfilePic: arshdeep,
    Letter: "A",
    Name: "Mr. Arashdeep Singh",
    Role: "Assistant Professor (HOD)",
  },

  {
    ProfilePic: tarundeep,
    Letter: "T",
    Name: "Mr. Tarundeep Singh",
    Role: "Assistant Professor (HOD)",
  },

  // {
  //   ProfilePic: dummy,
  //   Letter: "D",
  //   Name: "Mr. Deepak Paul",
  //   Role: "Dean HM Diploma in HM",
  // },
  {
    ProfilePic: rameshwer,
    Letter: "R",
    Name: "Dr. Rameshwer Singh",
    Role: "Associate Professor",
  },
  {
    ProfilePic: managaldeep,
    Letter: "M",
    Name: "Ms. Mangaldeep Urvashi",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: ashok,
    Letter: "A",
    Name: "Mr. Ashok Sharma",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: poojasonik,
    Letter: "P",
    Name: "Ms. Pooja Sonik",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "M",
    Name: "Mr. Manmohanjit Sidhu",
    Role: "Assistant Professor B.Sc. H&HA, MHMCT",
  },
  {
    ProfilePic: akshit,
    Letter: "A",
    Name: "Dr. Akshit Puri",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "H",
    Name: "Mr. Harshit Riat",
    Role: "Assistant Professor B.Sc. H&HA , M.Sc. H&HA",
  },
  {
    ProfilePic: dummy,
    Letter: "S",
    Name: "Ms. Simran Sood",
    Role: "Assistant Professor BHMCT , MBA HM",
  },
  {
    ProfilePic: dummy,
    Letter: "S",
    Name: "Ms. Shvinderpreet Kaur",
    Role: "Assistant Professor BTHM",
  },

  {
    ProfilePic: silky,
    Letter: "S",
    Name: "Ms. Silky Khurana",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: pallvi,
    Letter: "P",
    Name: "Ms. Pallavi Sharma",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: lovdeep,
    Letter: "L",
    Name: "Mr. Lovdeep Singh",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: pasricha,
    Letter: "P",
    Name: "Dr. Aman Pasricha",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: nancy,
    Letter: "N",
    Name: "Ms. Nancy Gupta",
    Role: "Associate Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "G",
    Name: "Mr. Gurjeet Kumar",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: jasmeen,
    Letter: "J",
    Name: "Ms. Jasmeen Kaur",
    Role: "Assistant Professor    ",
  },
  {
    ProfilePic: shiksha,
    Letter: "S",
    Name: "Ms. Shiksha Saxena",
    Role: "Assistant Professor    ",
  },
  {
    ProfilePic: lakhvinder,
    Letter: "L",
    Name: "Ms. Lakhvinder Kaur",
    Role: "Assistant Professor    ",
  },

  {
    ProfilePic: vikas,
    Letter: "V",
    Name: "Dr. Vikas Rattan",
    Role: "Associate Professor",
  },
  // {
  //   ProfilePic: dummy,
  //   Letter: "G",
  //   Name: "Mr. Gurnam Singh",
  //   Role: "Assistant Professor",
  // },
  {
    ProfilePic: dummy,
    Letter: "N",
    Name: "Mr. Navdeep Singh",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: sarbinder,
    Letter: "S",
    Name: "Mr. Sarbrinder Singh",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: amansharma,
    Letter: "A",
    Name: "Mr. Aman Sharma",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dilrose,
    Letter: "d",
    Name: "Ms. Dilrose Pabla",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: vishal,
    Letter: "V",
    Name: "Mr. Vishal",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: dummy,
    Letter: "H",
    Name: "Mr. Harsh Kakkar",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "G",
    Name: "Mr. Gagandeep Singh    ",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: gagandeep,
    Letter: "G",
    Name: "Ms. Gagandeep    ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "D",
    Name: "Ms. Diana",
    Role: " Lecturer",
  },

  // {
  //   ProfilePic: dummy,
  //   Letter: "P",
  //   Name: "Dr. Parvinder Singh",
  //   Role: "Assistant Professor",
  // },
  {
    ProfilePic: varun,
    Letter: "V",
    Name: "Mr. Varun Sharma",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: arvind,
    Letter: "A",
    Name: "Mr.Arvind Kumar Nanda",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: rajni,
    Letter: "R",
    Name: "Ms. Rajni Bedi",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: kritika,
    Letter: "K",
    Name: "Ms.  Kritika Wadhwa",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: dummy,
    Letter: "V",
    Name: "Mr. Vikram Dhiman ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: rakesh,
    Letter: "R",
    Name: "Mr. Rakesh Gagneja    ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "M",
    Name: "Mandeep",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "G",
    Name: "Ms. Geetanjli Reutela ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: geeta,
    Letter: "G",
    Name: "Ms. Geeta Money ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: reetika,
    Letter: "R",
    Name: "Ms. Reetika Goel",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "J",
    Name: "Ms. Jasdeep Kaur Basra ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "J",
    Name: "Ms. Jasdeep Kaur Basra     ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "P",
    Name: " Mr. Prashant Sharma    ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "J",
    Name: " Ms. Jasmeet Kaur     ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "P",
    Name: "Mr. Parminder Singh",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: dummy,
    Letter: "S",
    Name: "Ms. Shelly Walia    ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "M",
    Name: "Dr. Manisha    ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "P",
    Name: "Ms. Praneet kaur Dhaliwal",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: aasif,
    Letter: "A",
    Name: "Mr. Aasif Ahamd Dar ",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: manisha,
    Letter: "M",
    Name: "Ms. Manisha Uppal ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: harbinder,
    Letter: "H",
    Name: "Ms. Harbinder Kaur    ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "D",
    Name: "Ms. Damini",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "M",
    Name: "Ms. Monika ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "J",
    Name: "Ms. Jaswinder Kaur",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "V",
    Name: "Ms. K Vanisha ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: kuljeet,
    Letter: "K",
    Name: "Ms. Kuljeet Kaur",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "D",
    Name: "Mr .Daraksha Shabir    ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "A",
    Name: "Mr. Amandeep Paul    ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "F",
    Name: "Mr. Fakir Chand     ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: pankaj,
    Letter: "P",
    Name: " Mr. Pankaj Kumar     ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: puneet,
    Letter: "P",
    Name: " Ms. Puneet Kaur     ",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: dummy,
    Letter: "J",
    Name: "Mr. Jhujhar Singh Dosanjh    ",
    Role: "Professor in Economics    ",
  },
  {
    ProfilePic: dummy,
    Letter: "A",
    Name: "Mr. Anil Gangta    ",
    Role: "Assistant Director Brand Development",
  },

  {
    ProfilePic: tarunjit,
    Letter: "T",
    Name: "Dr. Tarunjit Singh    ",
    Role: "Associate Professor",
  },
  {
    ProfilePic: sonudua,
    Letter: "S",
    Name: "Dr. Sonu Dua    ",
    Role: " Associate Professor",
  },
  {
    ProfilePic: arminderjit,
    Letter: "A",
    Name: "Mr. Arminderjit Singh Rai     ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: bharat,
    Letter: "B",
    Name: "Mr. Bharat Bhushan Sharma",
    Role: "Assistant Professor",
  },
  // {
  //   ProfilePic: dummy,
  //   Letter: "K",
  //   Name: "Mr. Karanbir Singh Kler    ",
  //   Role: "Assistant Professor",
  // },
  {
    ProfilePic: anterpreet,
    Letter: "A",
    Name: "Ms. Anterpreet Kaur",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "B",
    Name: "Ms. Banita",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "A",
    Name: "Ms. Ashima Sofet",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: parul,
    Letter: "P",
    Name: "Ms. Parul Nanda",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "A",
    Name: "Mr. Abhishek Sharma",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: rupinder,
    Letter: "R",
    Name: "Dr. Rupinder  Sampla",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: simranjeet,
    Letter: "S",
    Name: "Ms. Simranjeet Kaur    ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: simranjeet1,
    Letter: "S",
    Name: "Ms. Simranjeet Kaur    ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: ruchi,
    Letter: "R",
    Name: "Ms. Ruchi Gulati",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: manjot,
    Letter: "M",
    Name: "Ms. Manjot Kaur",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: nitika,
    Letter: "N",
    Name: "Ms. Nitika Sehgal",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "P",
    Name: "Ms. Rashim Anand",
    Role: "Assistant Professor",
  },

  // {
  //   ProfilePic: dummy,
  //   Letter: "K",
  //   Name: "Dr. Kamal Nain Sharma",
  //   Role: "Assistant Professor",
  // },
  {
    ProfilePic: ashok,
    Letter: "A",
    Name: "Mr. Ashok Kumar",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: sapna,
    Letter: "S",
    Name: "Dr. Sapna",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: dummy,
    Letter: "R",
    Name: "Ms. Ranjana Bahri",
    Role: "Assistant Professor",
  },
  // {
  //   ProfilePic: dummy,
  //   Letter: "J",
  //   Name: "Ms. Jasleen",
  //   Role: "Assistant Professor",
  // },
  {
    ProfilePic: dummy,
    Letter: "S",
    Name: "Ms. Savita",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "P",
    Name: "Ms. Priya",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: raghav,
    Letter: "R",
    Name: "Mr. Raghav Gupta ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: amanpreet,
    Letter: "A",
    Name: "Ms. Amanpreet Kaur",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: minshu,
    Letter: "M",
    Name: "Ms. Manishu",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dhriti,
    Letter: "D",
    Name: "Ms. Dhriti Jain ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: anamika,
    Letter: "A",
    Name: "Ms. Anamika",
    Role: "Assistant Professor",
  },

  // {
  //   ProfilePic:dummy,Letter: "A",
  //   Name: "Mr. Aashish  Samuel",
  //   Role: " Lecturer",
  // },
  {
    ProfilePic: dummy,
    Letter: "S",
    Name: "Ms. Simardeep Kaur",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "S",
    Name: "Ms. Simran Sood",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: dummy,
    Letter: "A",
    Name: "Ms. Aarti Soni",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: sumitkumar,
    Letter: "S",
    Name: "Mr. Sumit Kumar",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: sujata,
    Letter: "S",
    Name: "Dr. Sujata",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: reena,
    Letter: "R",
    Name: "Ms. Reena Sharma",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: taranpreet,
    Letter: "R",
    Name: "Ms. Taranpreet Kaur",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: poojarana,
    Letter: "P",
    Name: "Ms. Pooja Rana",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: shahid,
    Letter: "S",
    Name: "Mr. Shahid Akbar Lone",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: sandeep,
    Letter: "S",
    Name: "Mr. Sandeep Rahar  ",
    Role: "Professor (HOD)",
  },

  {
    ProfilePic: bandana,
    Letter: "B",
    Name: "Ms. Bandana Nautiyal ",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: preeti,
    Letter: "P",
    Name: "Ms. Preeti Sidana ",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: narpat,
    Letter: "N",
    Name: "Mr. Narpat Singh ",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: shina,
    Letter: "S",
    Name: "Ms. Shaina Vasudeva ",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: shubham,
    Letter: "S",
    Name: "Mr. Shubham ",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: nikita,
    Letter: "N",
    Name: "Ms. Nikita Nayyar ",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: anchal,
    Letter: "A",
    Name: "Ms. Anchal Saini ",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: avnneet,
    Letter: "A",
    Name: "Ms. Avneet Kaur ",
    Role: "Assistant Professor",
  },
  {
    ProfilePic: kiran,
    Letter: "K",
    Name: "Ms. Kiran Kumari",
    Role: "Lecturer",
  },

  {
    ProfilePic: dummy,
    Letter: "S",
    Name: "Ms. Shvinder Preet Kaur",
    Role: "Lecturer",
  },
  {
    ProfilePic: harpreet,
    Letter: "H",
    Name: "Ms. Harpreet Kaur",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: navjot,
    Letter: "N",
    Name: "Ms. Navjot Kaur",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: jps,
    Letter: "J",
    Name: "Mr. Jatinder Pal Singh",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: tajamul,
    Letter: "T",
    Name: "Mr. Tajamul Rashid Wani",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: muzamil,
    Letter: "M",
    Name: "Mr. Muzamil Muhammad ",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: prabhjeet,
    Letter: "P",
    Name: "Ms. Prabhjeet Kaur",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: jps,
    Letter: "J",
    Name: "Mr. Jatinder Pal Singh",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: amankumar,
    Letter: "A",
    Name: "Mr. Aman Kumar",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: anika,
    Letter: "A",
    Name: "Ms. Anika",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: narinder,
    Letter: "N",
    Name: "Mr. Narinder Singh",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: tarandeep,
    Letter: "T",
    Name: "Ms. Tarandeep Kaur",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: kajal,
    Letter: "K",
    Name: "Ms. Kajal",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: sarabjit,
    Letter: "S",
    Name: "Ms. Sarabjit Kaur",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: amandeepkaurpharma,
    Letter: "A",
    Name: "Ms. Amandeep Kaur",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: aditi,
    Letter: "A",
    Name: "Ms. Aditi Sharma",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: vinayshwetha,
    Letter: "V",
    Name: "Ms. Vinay Shweta",
    Role: "Assistant Professor",
  },

  {
    ProfilePic: shahidradio,
    Letter: "S",
    Name: "Mr. Shahid Shabir",
    Role: "Assistant Professor",
  },
];
export const LabStaff = [
  {
    // ProfilePic: dummy,
    Letter: "S",
    Name: "Mr. Sukhveer Kumar",
    Role: "Lab",
  },
  {
    Letter: "A",
    Name: "Ms. Anjana Sharma",
    Role: "Lab Technician (Non Tech.)",
  },
  {
    Letter: "A",
    Name: "Mr. Amritpal Singh",
    Role: "Lab Technician (Non Tech.)",
  },
  {
    Letter: "H",
    Name: "Mr. Harpreet Singh",
    Role: "Workshop inst(Non Tech.)",
  },
  {
    Letter: "A",
    Name: "Mr. Ashwani Kumar",
    Role: "Workshop Instructor(Non Tech.)",
  },
  {
    Letter: "G",
    Name: "Mr. Gurpal Singh",
    Role: "Lab Technician (Non Tech.)",
  },
  {
    Letter: "S",
    Name: "Mr. Surender Kumar",
    Role: "Workshop Instructor(Non Tech.)",
  },
  {
    Letter: "S",
    Name: "Mr. Shadi Lal",
    Role: "Lab Technician (Non Tech.)",
  },
  {
    Letter: "A",
    Name: "Mr. Jatinder",
    Role: "Lab Technician (Non Tech.)",
  },
  {
    Letter: "R",
    Name: "Mr. Raman Kumar",
    Role: "Lab Technician (Non Tech.)",
  },
  {
    Letter: "M",
    Name: "Mandeep",
    Role: "Network Adm.",
  },
  {
    Letter: "R",
    Name: "Rahulpreet Singh",
    Role: "LAN Admin",
  },
  {
    Letter: "S",
    Name: "Mr. Santosh",
    Role: "Lab Technician (Non Tech.)",
  },
  {
    Letter: "R",
    Name: "Mr. Ravi",
    Role: "Lab Technician (Non Tech.)",
  },
  {
    Letter: "K",
    Name: "Ms. Kirandeep",
    Role: "Lab Technician (Non Tech.)",
  },
  {
    Letter: "K",
    Name: " Mr. Vikas Khokar",
    Role: "Lab Technician",
  },
  {
    Letter: "M",
    Name: "Mr. Muneesh",
    Role: "Lab Technician (Non Tech.)",
  },
  {
    Letter: "D",
    Name: "Mr. Dinesh",
    Role: "Lab Technician (Non Tech.)",
  },
  {
    Letter: "M",
    Name: "Ms. Mikshali",
    Role: "Lab Technician (Non Tech.)",
  },
  {
    Letter: "A",
    Name: "Er. Amandeep Kaur Sekhon",
    Role: "Network Administrator",
  },
  {
    Letter: "S",
    Name: "Mr. Sukhpal",
    Role: "Lab Assistant",
  },
  {
    Letter: "R",
    Name: "Ms. Roshni",
    Role: "Lab Assistant",
  },
  {
    Letter: "R",
    Name: "Mr. Ranjeev Pathania",
    Role: "Lab Attendant (Stores) Dilpoma (HM)",
  },
  {
    Letter: "R",
    Name: "Ms. Rani",
    Role: "Lab Attendant (Bakery) Diploma (HM)",
  },
];
export const NonteachingStaff = [
  {
    Letter: "S",
    Name: "Mr. Shyam Lal",
    Role: "Office Supdt.",
  },
  {
    Letter: "R",
    Name: "Ms. Rajvir Kaur",
    Role: "Office Assistant.",
  },
  {
    Letter: "K",
    Name: "Ms. Kaushalya",
    Role: "Office Assistant.",
  },
  {
    Letter: "S",
    Name: "Mr. Shyam Lal",
    Role: "Office Supdt.",
  },
  {
    Letter: "R",
    Name: "Ms. Ramandeep Kaur",
    Role: "Office Supdt.",
  },
  {
    Letter: "M",
    Name: "Mr. Mannu",
    Role: "MSK",
  },
  {
    Letter: "A",
    Name: "Ms. Amandeep Kaur",
    Role: "Office Assistant.",
  },
  {
    Letter: "N",
    Name: "Ms. Namita Rawat",
    Role: "Office Assistant.",
  },
];
export const Library = [
  {
    Letter: "B",
    Name: "Mr. Baljit Singh",
    Role: " Librarian",
  },
  {
    Letter: "R",
    Name: "Ms. Ranjit kaur ",
    Role: "Office Assistant.",
  },
  {
    Letter: "S",
    Name: "Ms. Surinder Kaur",
    Role: "Library Assistant",
  },
  {
    Letter: "G",
    Name: "Mr. Gurmeet Singh",
    Role: "Library Assistant",
  },
  {
    Letter: "D",
    Name: " Mr. Deepak",
    Role: "Library Assistant",
  },
  {
    Letter: "N",
    Name: "Ms. Navneet ",
    Role: "Library Restorer",
  },
];
export const AccountStaff = [
  {
    Letter: "S",
    Name: "Mr. Surendera Sharma",
    Role: "Accountant",
  },
  {
    Letter: "R",
    Name: "Mr. Ranjeet Singh",
    Role: "Assistant Accountant",
  },
  {
    Letter: "P",
    Name: "Ms. Priya",
    Role: "Office Assistant",
  },
];
