import * as React from "react";

import CourseOutcomeMLS from "../../../assets/pdf/MLS.pdf";

export const BscMLS = () => {
  return (
    <div>
      <div className="xs:px-4">
        <div className="md:px-[24px] lg:px-0">
          <div className="primary-color container mt-8 ml-0 w-72  border-l-4 border-[#32727a]  pl-3 text-3xl font-bold xxs:w-[90%]  xs:mt-20 xs:w-[95%] xs:text-4xl sm:w-[70%] lg:w-[85%] lg:text-5xl">
            B.Sc. (Medical Lab Sciences)
          </div>
          <div className="mt-14 grid grid-cols-1 gap-4 text-white sm:px-10  md:px-0 lg:grid-cols-3 lg:gap-9">
            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              Duration: 4 years ( 8 Semesters)
            </div>
            <div className="secondary-bg-color  h-16 items-center rounded-md p-4 text-center text-lg">
              Eligibility: 10+ 2 (Med. or Non – Med.)
            </div>
            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              No of seats: 60
            </div>
          </div>
          <div className="mt-14 grid grid-cols-1 gap-4 text-white sm:px-10  md:px-0 lg:grid-cols-3 lg:gap-9">
            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              LEET{" "}
            </div>
            <div className="secondary-bg-color  h-16 items-center rounded-md p-4 text-center text-lg">
              Eligibility: Diploma in MLT or D. Pharma
            </div>
            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              Duration: Leet 3 Years
            </div>
          </div>
          <div className=" mt-6">
            <div className="text-2xl font-semibold text-[#003857]">Vision</div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              B.Sc Medical Laboratory Sciences at Inder Kumar Gujral Punjab
              Technical University envisions producing competent professionals
              equipped with the knowledge and skills to excel in the dynamic
              field of medical diagnostics. The program aims to foster a deep
              understanding of laboratory techniques, diagnostic procedures, and
              medical technologies among students. Rooted in the principles of
              excellence and innovation, the curriculum emphasizes both
              theoretical concepts and practical applications, ensuring
              graduates are adept at handling modern laboratory equipment and
              interpreting diagnostic results accurately. Moreover, the program
              encourages research and critical thinking, empowering students to
              contribute to advancements in medical science. Aligned with the
              university's commitment to societal welfare, B.Sc Medical
              Laboratory Sciences endeavors to produce graduates who are not
              only proficient in their field but also compassionate healthcare
              providers. Students are instilled with ethical values and a sense
              of responsibility towards patients, ensuring they uphold the
              highest standards of professionalism and patient care. Through
              rigorous academic training, practical experience, and exposure to
              emerging trends in medical diagnostics, graduates of the program
              are poised to make significant contributions to healthcare
              delivery, disease prevention, and medical research. Ultimately,
              the vision is to produce well-rounded professionals who play a
              pivotal role in improving healthcare outcomes and enhancing the
              quality of life for individuals and communities alike.
            </div>
            <div className="text-2xl font-semibold text-[#003857]">Mission</div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <ul className="list-disc pl-4 text-justify text-[17px]">
                <li>
                  Excellence in Education: Our primary mission is to provide a
                  high-quality education in medical laboratory sciences,
                  blending theoretical knowledge with practical skills. We
                  strive to ensure that students receive comprehensive training
                  that prepares them for professional success in diverse
                  laboratory settings.
                </li>

                <li>
                  Ethical Practice: We are committed to instilling ethical
                  values and professional integrity in our students. Our mission
                  is to cultivate a culture of responsible and ethical practice,
                  ensuring that graduates uphold the highest standards of
                  integrity and professionalism in their careers.
                </li>
                <li>
                  Lifelong Learning: Our mission extends beyond graduation. We
                  aim to foster a spirit of lifelong learning among our
                  students, equipping them with the skills and mindset necessary
                  to adapt to the ever-evolving field of medical laboratory
                  sciences.
                </li>
                <li>
                  Community Engagement: We believe in the importance of giving
                  back to the community. Our mission includes promoting
                  community engagement and service among our students,
                  encouraging them to use their skills and knowledge for the
                  betterment of society.
                </li>
                <li>
                  Innovation and Research: We are dedicated to fostering a
                  culture of innovation and research within the field of medical
                  laboratory sciences. Our mission is to empower students to
                  think critically, explore new ideas, and contribute to
                  advancements in the field through research and innovation.
                </li>
              </ul>
            </div>
            <div className="text-2xl font-semibold text-[#003857]">
              Program Specific Outcomes
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">
                PSO.1 Diagnostic Competence:
              </span>{" "}
              Graduates will demonstrate proficiency in conducting various
              diagnostic tests and analyses accurately and efficiently.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">
                PSO.2 Laboratory Management Skills:
              </span>{" "}
              Students will acquire knowledge and skills in laboratory
              management, including equipment maintenance, quality control, and
              adherence to safety protocols.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO.3 Research Proficiency:</span>{" "}
              Graduates will possess the ability to design and execute research
              studies, analyze data, and contribute to scientific knowledge in
              the field of medical laboratory sciences.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">
                PSO.4 Communication Mastery:
              </span>{" "}
              Students will develop effective communication skills to convey
              complex scientific information clearly and professionally to
              diverse audiences.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO.5 Ethical Practice:</span>{" "}
              Graduates will adhere to ethical principles and professional
              standards in their work, including patient confidentiality,
              integrity, and honesty.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO.6 Continuous Learning:</span>{" "}
              Students will embrace a commitment to lifelong learning, seeking
              opportunities for professional development and staying updated
              with advancements in the field.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO.7 Community Engagement:</span>{" "}
              Graduates will engage in community health initiatives, promoting
              health education and awareness to improve public health outcomes.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">
                PSO.8 Interdisciplinary Collaboration:
              </span>{" "}
              Students will collaborate effectively with healthcare
              professionals from different disciplines, fostering teamwork and
              integrated patient care.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO.9 Critical Thinking:</span>{" "}
              Graduates will develop critical thinking skills to evaluate
              scientific literature, solve problems, and make evidence-based
              decisions in laboratory practice.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">
                PSO.10 Technological Proficiency:
              </span>{" "}
              Students will be proficient in utilizing modern laboratory
              technologies and information systems to enhance diagnostic
              accuracy and efficiency.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">
                PSO.11 Leadership Potential:
              </span>{" "}
              Graduates will demonstrate leadership potential, capable of
              assuming roles in laboratory management, research, education, and
              advocacy within the healthcare sector.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO.12 Global Perspective:</span>{" "}
              Students will develop a global perspective, understanding the
              cultural, social, and economic factors influencing healthcare
              delivery and medical laboratory practice worldwide.
            </div>

            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">
                PSO.1 Diagnostic Competence:
              </span>{" "}
              Graduates will demonstrate proficiency in conducting various
              diagnostic tests and analyses accurately and efficiently.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">
                PSO.2 Laboratory Management Skills:
              </span>{" "}
              Students will acquire knowledge and skills in laboratory
              management, including equipment maintenance, quality control, and
              adherence to safety protocols.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO.3 Research Proficiency:</span>{" "}
              Graduates will possess the ability to design and execute research
              studies, analyze data, and contribute to scientific knowledge in
              the field of medical laboratory sciences.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">
                PSO.4 Communication Mastery:
              </span>{" "}
              Students will develop effective communication skills to convey
              complex scientific information clearly and professionally to
              diverse audiences.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO.5 Ethical Practice:</span>{" "}
              Graduates will adhere to ethical principles and professional
              standards in their work, including patient confidentiality,
              integrity, and honesty.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO.6 Continuous Learning:</span>{" "}
              Students will embrace a commitment to lifelong learning, seeking
              opportunities for professional development and staying updated
              with advancements in the field.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO.7 Community Engagement:</span>{" "}
              Graduates will engage in community health initiatives, promoting
              health education and awareness to improve public health outcomes.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">
                PSO.8 Interdisciplinary Collaboration:
              </span>{" "}
              Students will collaborate effectively with healthcare
              professionals from different disciplines, fostering teamwork and
              integrated patient care.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO.9 Critical Thinking:</span>{" "}
              Graduates will develop critical thinking skills to evaluate
              scientific literature, solve problems, and make evidence-based
              decisions in laboratory practice.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">
                PSO.10 Technological Proficiency:
              </span>{" "}
              Students will be proficient in utilizing modern laboratory
              technologies and information systems to enhance diagnostic
              accuracy and efficiency.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">
                PSO.11 Leadership Potential:
              </span>{" "}
              Graduates will demonstrate leadership potential, capable of
              assuming roles in laboratory management, research, education, and
              advocacy within the healthcare sector.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO.12 Global Perspective:</span>{" "}
              Students will develop a global perspective, understanding the
              cultural, social, and economic factors influencing healthcare
              delivery and medical laboratory practice worldwide.
            </div>

            <div className="">
              <div className="mt-8 text-2xl font-semibold text-[#003857]">
                Course Rationale
              </div>
              <div className=" mt-1 break-words text-justify text-lg tracking-wider">
                The course rationale for the B.Sc. Medical Laboratory Sciences
                program at I.K Gujral Punjab Technical University lies in
                addressing the growing demand for skilled professionals in the
                field of healthcare diagnostics. By offering a comprehensive
                curriculum encompassing theoretical knowledge and practical
                skills, the program aims to fulfill the need for proficient
                laboratory technicians capable of conducting accurate diagnostic
                tests and analyses. Furthermore, the course emphasizes ethical
                practice, research proficiency, and continuous learning,
                ensuring graduates are well-prepared to meet the challenges of
                the evolving healthcare landscape while upholding the highest
                standards of professionalism and patient care.
              </div>
            </div>
            <div className="">
              {/* <a href={CourseOutcomeMLS} target="_blank" rel="noreferrer">
                <div className="mt-8 text-2xl font-semibold text-[#003857] underline cursor-pointer">
                  Course Outcome
                </div>
              </a> */}
              <div className="mt-8 text-2xl font-semibold text-[#003857]">
                Job Opportunities
              </div>
              <ul className="list-disc pl-4 text-justify text-[17px]">
                <li>
                  Medical Laboratory Technologist/Scientist: Working in
                  hospitals, clinics, or diagnostic laboratories, technologists
                  perform a variety of laboratory tests on patient samples to
                  assist in the diagnosis and treatment of diseases.
                </li>
                <li>
                  Clinical Research Associate: Graduates can work in clinical
                  research organizations, pharmaceutical companies, or academic
                  institutions, assisting in the design, implementation, and
                  monitoring of clinical trials.
                </li>
                <li>
                  Public Health Laboratory Technician: In public health
                  laboratories, technicians conduct tests to monitor and control
                  the spread of diseases, analyze environmental samples, and
                  contribute to disease surveillance efforts.
                </li>
                <li>
                  Quality Assurance/Quality Control Officer: Professionals in
                  this role ensure that laboratory procedures and processes
                  comply with regulatory standards and maintain the quality and
                  accuracy of test results.
                </li>
                <li>
                  Medical Laboratory Supervisor/Manager: With experience,
                  graduates can advance to supervisory or managerial roles,
                  overseeing laboratory operations, staff management, and
                  quality improvement initiatives.
                </li>
                <li>
                  Biotechnology Industry: Opportunities exist in biotechnology
                  companies involved in the development and production of
                  diagnostic tests, medical devices, and pharmaceuticals.
                </li>
                <li>
                  Healthcare Administration: Graduates may pursue administrative
                  roles in healthcare settings, such as laboratory directors,
                  laboratory managers, or healthcare administrators, overseeing
                  laboratory services and operations.
                </li>
                <li>
                  Education and Training: Some graduates choose to pursue
                  careers in education and training, becoming instructors or
                  educators in medical laboratory science programs, training new
                  technologists, or conducting workshops and seminars.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const OperationalTab = () => {
  return (
    <div>
      <div className="xs:px-4">
        <div className="md:px-[24px] lg:px-0">
          <div className="primary-color container mt-8 ml-0 w-72  border-l-4 border-[#32727a]  pl-3 text-3xl font-bold xxs:w-[90%]  xs:mt-20 xs:w-[95%] xs:text-4xl sm:w-[70%] lg:w-[85%] lg:text-5xl">
            B.Sc. Medical Technology Anaesthesia and Operation Theatre
          </div>
          <div className="mt-14 grid grid-cols-1 gap-4 text-white sm:px-10  md:px-0 lg:grid-cols-3 lg:gap-9">
            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              Duration: 4 years ( 8 Semesters)
            </div>
            <div className="secondary-bg-color  h-16 items-center rounded-md p-4 text-center text-lg">
              Eligibility: 10 + 2 (MEDICAL)
            </div>
            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              No of seats: 60
            </div>
          </div>
          <div className="mt-14 grid grid-cols-1 gap-4 text-white sm:px-10  md:px-0 lg:grid-cols-3 lg:gap-9">
            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              LEET
            </div>
            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              Duration: Leet 3 Years
            </div>
            <div className="secondary-bg-color  h-16 items-center rounded-md p-4 text-center text-lg">
              Eligibility : Diploma in OT or D-Pharma
            </div>
          </div>

          <div className=" mt-6">
            <div className="text-2xl font-semibold text-[#003857]">Vision</div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              Our vision for the BSc in Medical Technology with a specialization
              in Anesthesia and Operation Theatre Technology is to produce
              highly skilled professionals who play a pivotal role in ensuring
              the safety, efficiency, and effectiveness of surgical procedures
              and anesthesia administration. we aim to provide students with a
              thorough understanding of operation theatre protocols, sterile
              techniques, and surgical instrumentation, preparing them to assist
              surgeons seamlessly during procedures and maintain optimal
              operation theatre conditions.
            </div>
            <div className="text-2xl font-semibold text-[#003857]">Mission</div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              The mission of a Bachelor of Science (BSc) in Medical Technology
              with a specialization in Anesthesia and Operation Theatre
              Technology is to Provide students with a rigorous academic
              curriculum that encompasses theoretical knowledge and practical
              skills essential for anesthesia administration and operation
              theatre management. Equip students with the technical expertise to
              administer anesthesia safely and effectively, as well as manage
              operation theatres efficiently, ensuring optimal conditions for
              surgical procedures. Provide opportunities for students to engage
              in research projects and innovation initiatives aimed at advancing
              the field of anesthesia administration, operation theatre
              technology, and surgical healthcare delivery.
            </div>
            <div className="text-2xl font-semibold text-[#003857]">
              Program Specific Outcomes
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className=" font-semibold">
                PSO-1:- Proficiency in Anesthesia Administration
              </span>
              Graduates should demonstrate competency in administering various
              types of anesthesia, including general, regional, and local
              anesthesia, while adhering to established protocols and safety
              guidelines.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO-2:</span> Operation Theatre
              Management Skills: Students should be capable of effectively
              managing operation theatre facilities, including maintaining
              sterility, organizing surgical instruments, and coordinating with
              surgical teams to ensure smooth and efficient surgical procedures.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO-3:</span> Patient Monitoring
              and Safety: Graduates should be proficient in monitoring patients'
              vital signs, anesthesia depth, and overall well-being during
              surgical procedures, prioritizing patient safety and responding
              appropriately to any complications or emergencies.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO-4:</span> Technical Expertise
              in Equipment Handling: Students should demonstrate proficiency in
              handling and operating anesthesia machines, monitoring devices,
              surgical instruments, and other equipment used in operation
              theatre settings, ensuring accurate and reliable performance.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO-5:</span> Interdisciplinary
              Collaboration: Graduates should be able to collaborate effectively
              with surgeons, anesthesiologists, nurses, and other healthcare
              professionals as integral members of the surgical team,
              demonstrating effective communication, teamwork, and
              professionalism.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO-6:</span> Ethical and
              Professional Conduct: Graduates should adhere to ethical
              principles and professional standards in all aspects of their
              practice, including maintaining patient confidentiality,
              respecting patient autonomy, and upholding integrity and honesty
              in their interactions with patients and colleagues.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO-7:</span> Community Engagement
              and Service: Students should recognize the importance of community
              engagement and service, demonstrating a commitment to promoting
              health and wellness in diverse populations and addressing
              healthcare disparities through outreach initiatives and
              volunteering efforts.
            </div>

            <div className="">
              <div className="mt-8 text-2xl font-semibold text-[#003857]">
                Course Rationale
              </div>
              <div className=" mt-1 break-words text-justify text-lg tracking-wider">
                The rationale behind offering a Bachelor of Science (BSc) in
                Medical Technology with a specialization in Anesthesia and
                Operation Theatre Technology lies in addressing the specific
                needs of the healthcare industry, particularly in surgical
                settings. Anesthesia technologists and operation theatre
                technicians play critical roles in patient care during surgical
                interventions. Their expertise in anesthesia administration,
                patient monitoring, and operation theatre management directly
                impacts patient. Ensuring patient safety and maintaining
                high-quality standards in surgical care are paramount. A
                dedicated program focusing on anesthesia and operation theatre
                technology equips students with the knowledge and skills to
                uphold these standards and contribute to quality improvement
                initiatives. They can pursue roles as anesthesia technologists,
                operation theatre technicians, surgical assistants, or progress
                to higher positions with additional education and experience.
              </div>
            </div>
            <div className="">
              <div className="mt-8 text-2xl font-semibold text-[#003857]">
                CAREER OPPORTUNITIES
              </div>
              <ul className="list-disc pl-4 text-justify text-[17px]">
                <li>Lab Technician</li>
                <li>Teaching Profession</li>
                <li>OT Technician/Assistant</li>
                <li>Anesthesia Technician</li>
                <li>CSSD Technician</li>
                <li>ICU Technician</li>
                <li>Operation Theater Manager</li>
                <li>Emergency Medical Technician</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RadiologyTab = () => {
  return (
    <div>
      <div className="xs:px-4">
        <div className="md:px-[24px] lg:px-0">
          <div className="primary-color container mt-8 ml-0 w-72  border-l-4 border-[#32727a]  pl-3 text-3xl font-bold xxs:w-[90%]  xs:mt-20 xs:w-[95%] xs:text-4xl sm:w-[70%] lg:w-[85%] lg:text-5xl">
            B.Sc. Radiology & Imaging Technology
          </div>
          <div className="mt-14 grid grid-cols-1 gap-4 text-white sm:px-10  md:px-0 lg:grid-cols-3 lg:gap-9">
            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              Duration: 4 years ( 8 Semesters)
            </div>
            <div className="secondary-bg-color  h-16 items-center rounded-md p-4 text-center text-lg">
              Eligibility: 10 + 2 (MEDICAL)
            </div>
            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              No of seats: 60
            </div>
          </div>
          <div className="mt-14 grid grid-cols-1 gap-4 text-white sm:px-10  md:px-0 lg:grid-cols-3 lg:gap-9">
            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              LEET
            </div>

            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              Duration: Leet 3 Years
            </div>
            <div className="secondary-bg-color  h-16 items-center rounded-md p-4 text-center text-lg">
              Eligibility : Diploma RIT or D-Pharma
            </div>
          </div>
          <div className=" mt-6">
            <div className="text-2xl font-semibold text-[#003857]">Vision</div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              The vision of a B.Sc. Radiology program typically revolves around
              producing competent radiology professionals who are equipped with
              the knowledge, skills, and values necessary to excel in the field.
              To empower students with a comprehensive understanding of
              radiological sciences, cutting-edge imaging technologies, and
              ethical practices, fostering a commitment to excellence in patient
              care, research, and community service. Our vision is to cultivate
              skilled radiology professionals who play a pivotal role in
              healthcare delivery, driving innovation, and promoting wellness
              for individuals and communities.
            </div>
            <div className="text-2xl font-semibold text-[#003857]">Mission</div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <ul className="list-disc pl-4 text-justify text-[17px]">
                The mission of a B.Sc. Radiology program is to provide students
                with a rigorous and comprehensive education in radiological
                sciences, imaging techniques, and patient care. The program aims
                to:
                <li>
                  Equip students with theoretical knowledge and practical skills
                  in radiography, diagnostic imaging, and radiation therapy,
                  enabling them to perform effectively in clinical settings.
                </li>
                <li>
                  Foster critical thinking, problem-solving, and communication
                  skills essential for accurate diagnosis, patient interaction,
                  and collaboration within multidisciplinary healthcare teams.
                </li>
                <li>
                  Instill a commitment to ethical practice, patient safety, and
                  professional integrity, adhering to established standards and
                  guidelines in the field of radiology.
                </li>
                <li>
                  Facilitate hands-on training and experiential learning
                  opportunities in state-of-the-art imaging facilities,
                  preparing students for the challenges and advancements in
                  modern radiology practice.
                </li>
              </ul>
            </div>
            <div className="text-2xl font-semibold text-[#003857]">
              Program Specific Outcomes
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              Program-specific outcomes (PSOs) for a B.Sc. Radiology program
              outline the specific skills, knowledge, and attributes that
              students are expected to acquire upon completion of the program.
              These outcomes reflect the program's goals and objectives and
              serve as benchmarks for evaluating student achievement. Here are
              some potential PSOs for a B.Sc. Radiology program:
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO 1:</span> Graduates will
              demonstrate proficiency in conducting various radiographic
              techniques, including positioning, exposure settings, and
              equipment operation, ensuring high-quality diagnostic images while
              minimizing patient discomfort and radiation exposure.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO 2:</span> Graduates will be
              able to interpret radiographic images accurately, identifying
              anatomical structures, pathological conditions, and technical
              artifacts, and communicate findings effectively to healthcare
              providers for clinical diagnosis and treatment planning.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO 3:</span> Graduates will
              understand principles of radiation safety and protection, adhering
              to established protocols and guidelines to ensure the safety of
              patients, themselves, and others in the healthcare environment.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO 4:</span> Graduates will
              demonstrate proficiency in providing compassionate and
              patient-centered care, effectively communicating with patients,
              addressing their concerns, and ensuring their comfort and
              cooperation during imaging procedures.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO 5:</span> Graduates will be
              able to implement quality assurance and control measures in
              radiography practice, including equipment maintenance, image
              processing, and compliance with regulatory standards, to ensure
              the accuracy and reliability of diagnostic images.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO 6:</span> Graduates will
              exhibit professional ethics and behavior in their practice,
              maintaining confidentiality, respecting patient autonomy, and
              upholding the highest standards of integrity and professionalism
              in all interactions with patients, colleagues, and healthcare
              stakeholders.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PSO 7:</span> Graduates will
              demonstrate a commitment to lifelong learning and professional
              development, engaging in continuing education, research, and
              participation in professional organizations to stay abreast of
              advancements in radiology practice and contribute to the
              advancement of the field.
            </div>

            <div className="">
              <div className="mt-8 text-2xl font-semibold text-[#003857]">
                Course Rationale
              </div>
              <div className=" mt-1 break-words text-justify text-lg tracking-wider">
                Radiography and Imaging Technology is an undergraduate program
                which mainly focuses on radiology and imaging technology about
                human anatomy. It familiarizes the course takers how to handle
                Radiology and Imaging equipment Students who study this course
                have decent job opportunities as it is one of the growing fields
                in medicine.Also this is a quite unusual course rather than the
                usual BA or BSc courses and hence lots of job openings are
                available, since this field lacks professionals.
              </div>
            </div>
            <div className="">
              <div className="mt-8 text-2xl font-semibold text-[#003857]">
                CAREER OPPORTUNITIES
              </div>
              <ul className="list-disc pl-4 text-justify text-[17px]">
                <li>Radiological Technologist</li>
                <li>MRI Technologist</li>
                <li>CT Technologist</li>
                <li>X-Ray Technician</li>
                <li>Dark Room Technician</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PGMicrobiologyTab = () => {
  return (
    <div>
      <div className="xs:px-4">
        <div className="md:px-[24px] lg:px-0">
          <div className="primary-color container mt-8 ml-0 w-72  border-l-4 border-[#32727a]  pl-3 text-3xl font-bold xxs:w-[90%]  xs:mt-20 xs:w-[95%] xs:text-4xl sm:w-[70%] lg:w-[85%] lg:text-5xl">
            M.Sc. (Medical Microbiology)
          </div>
          <div className="mt-14 grid grid-cols-1 gap-4 text-white sm:px-10  md:px-0 lg:grid-cols-3 lg:gap-9">
            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              Duration: 2 Years
            </div>
            {/* <div className="secondary-bg-color  h-16 items-center rounded-md p-4 text-center text-lg">
              Eligibility: B.Sc. (Microbiology, Biotechnology, Biology,
              Biochemistry, or a related discipline)
            </div> */}
            <div
              style={{
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              className="secondary-bg-color h-16 items-center rounded-md p-4 text-center text-lg"
            >
              Eligibility: B.Sc. (Microbiology, Biotechnology, Biology,
              Biochemistry, or a related discipline)
            </div>

            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              No of seats: 30
            </div>
          </div>
          <div className=" mt-6">
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className=" font-semibold">Eligibility: </span> B.Sc.
              (Microbiology, Biotechnology, Biology, Biochemistry, or a related
              discipline)
            </div>
            <div className="text-2xl font-semibold text-[#003857]">Vision</div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              The vision for a Master of Science (MSc) program in Medical
              Microbiology may vary depending on the specific goals and
              objectives of the educational institution offering the program. To
              be a leading academic program in Medical Microbiology, committed
              to excellence in education, research, and service, fostering a
              dynamic and innovative environment that prepares students to
              address current and emerging challenges in the field of medical
              microbiology." Medical microbiology involves the identification of
              microorganisms for the diagnosis of infectious diseases and the
              assessment of likely response to specific therapeutic
              interventions. Medical microbiology is a branch of microbiology
              that specifically focuses on the study of microorganisms (such as
              bacteria, viruses, fungi, and parasites) and their relationship to
              human health and disease. It plays a crucial role in understanding
              the causes, prevention, diagnosis, and treatment of infectious
              diseases. This field is used to Foster students' growth towards
              quality healthcare professionals to carry out creative, innovative
              and inventive research, and provide reliable diagnostic services
              in the field of medical microbiology.
            </div>
            <div className="text-2xl font-semibold text-[#003857]">Mission</div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <ul className="list-disc pl-4 text-justify text-[17px]">
                <li>
                  Provide a comprehensive and rigorous curriculum that equips
                  students with advanced knowledge and skills in medical
                  microbiology.
                </li>
                <li>
                  Foster critical thinking, analytical skills, and a deep
                  understanding of the principles and applications of medical
                  microbiology.
                </li>
                <li>
                  Conduct cutting-edge research in medical microbiology to
                  contribute to the advancement of knowledge and the development
                  of new technologies.
                </li>
                <li>
                  Encourage students and faculty to engage in research projects
                  that address important issues in the field.
                </li>
                <li>
                  Prepare graduates to excel in diverse careers related to
                  medical microbiology, including clinical diagnostics,
                  research, academia, and public health.
                </li>
                <li>
                  Foster a culture of lifelong learning, encouraging graduates
                  to stay current with developments in the field.
                </li>
                <li>
                  Foster collaboration with healthcare institutions, research
                  organizations, and industry partners to enhance opportunities
                  for applied research and experiential learning.
                </li>
                <li>
                  Engage in community outreach and public education initiatives
                  to raise awareness about the importance of medical
                  microbiology in healthcare and public health.
                </li>
                <li>
                  Instill ethical values and a strong sense of social
                  responsibility in students, emphasizing the importance of
                  integrity, accountability, and inclusivity in the practice of
                  medical microbiology.
                </li>
                <li>
                  It's important for these statements to reflect the specific
                  goals and values of the institution offering the MSc in
                  Medical Microbiology. These statements serve as guiding
                  principles to shape the direction and activities of the
                  program, aligning with the broader mission of the educational
                  institution.
                </li>
              </ul>
            </div>
            <div className="text-2xl font-semibold text-[#003857]">
              Program Specific Outcomes
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className=" font-semibold">PSO 1:</span> Demonstrate
              advanced knowledge of the principles and concepts of medical
              microbiology, including the structure, function, and
              classification of microorganisms.
            </div>

            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className=" font-semibold">PSO 2:</span> Understand the
              molecular and cellular mechanisms underlying microbial
              pathogenesis and the immune response.
            </div>

            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className=" font-semibold">PSO 3:</span> Develop proficiency
              in laboratory techniques for the isolation, identification, and
              characterization of microorganisms, including the use of advanced
              molecular biology and genomics tools.
            </div>

            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className=" font-semibold">PSO 4:</span> Apply quality
              control measures and adhere to ethical standards in laboratory
              practices related to medical microbiology.
            </div>

            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className=" font-semibold">PSO 5:</span> Demonstrate the
              ability to diagnose and differentiate microbial infections using
              various diagnostic methods, including culture-based, serological,
              and molecular approaches.
            </div>

            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className=" font-semibold">PSO 6:</span> Interpret and
              analyze laboratory results to provide accurate and timely
              information for patient diagnosis and management.
            </div>

            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className=" font-semibold">PSO 7:</span> Conduct independent
              research in medical microbiology, including the design, execution,
              and analysis of experiments or studies.
            </div>

            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className=" font-semibold">PSO 8:</span> Contribute to the
              advancement of knowledge in the field through the development of a
              research thesis or project.
            </div>

            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className=" font-semibold">PSO 9:</span> Apply critical
              thinking skills to analyze complex problems in medical
              microbiology, formulate hypotheses, and propose evidence-based
              solutions.
            </div>

            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className=" font-semibold">PSO 10:</span> Evaluate and
              synthesize scientific literature to stay current with advancements
              in medical microbiology.
            </div>

            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className=" font-semibold">PSO 11:</span> Effectively
              communicate scientific findings, both orally and in writing, to
              diverse audiences, including peers, healthcare professionals, and
              the broader community.
            </div>

            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className=" font-semibold">PSO 12:</span> Collaborate with
              interdisciplinary teams and present research findings at
              conferences or scientific meetings.
            </div>

            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className=" font-semibold">PSO 13:</span> Demonstrate
              professionalism, ethical conduct, and social responsibility in the
              practice of medical microbiology, considering the implications for
              patient care and public health.
            </div>

            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className=" font-semibold">PSO 14:</span> Uphold the highest
              standards of integrity and responsibility in research and
              professional interactions.
            </div>

            <div className="">
              <div className="mt-8 text-2xl font-semibold text-[#003857]">
                Course Rationale
              </div>
              <div className=" mt-1 break-words text-justify text-lg tracking-wider">
                Medical Microbiology is a foundational course designed to
                provide students with a comprehensive understanding of
                microorganisms and their impact on human health. In the field of
                healthcare, a thorough knowledge of medical microbiology is
                essential for effective disease diagnosis, treatment, and
                prevention. This course serves as the bridge between basic
                microbiological principles and their practical applications in
                the context of medicine.
              </div>
            </div>
            <div className="">
              <div className="mt-8 text-2xl font-semibold text-[#003857]">
                CAREER OPPORTUNITIES
              </div>
              <ul className="list-disc pl-4 text-justify text-[17px]">
                <li>Clinical Microbiologist</li>
                <li>Research Scientist</li>
                <li>Public Health Microbiologist</li>
                <li>Industrial Microbiologist</li>
                <li>Infection Control Specialist</li>
                <li>Environmental Microbiologist</li>
                <li>Assistant Professor</li>
                <li>Pharmaceutical Sales and Marketing</li>
                <li>Quality Assurance/Control Specialist</li>
                <li>Forensic Microbiologist</li>
              </ul>
            </div>

            <div className="">
              <div className="mt-8 text-2xl font-semibold text-[#003857]">
                Labs and Infrastructure{" "}
              </div>
              <ul className="list-disc pl-4 text-justify text-[17px]">
                <li>Systemic Bacteriology Lab</li>
                <li>Clinical Microbiology Lab</li>
                <li>Immunology and Mycology Lab</li>
                <li>Biochemistry Lab</li>
                <li>Human Anatomy Physiology Lab</li>
                <li>Haematology Lab</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PGMedicalTechnology = () => {
  return (
    <div>
      <div className="xs:px-4">
        <div className="md:px-[24px] lg:px-0">
          <div className="primary-color container mt-8 ml-0 w-72  border-l-4 border-[#32727a]  pl-3 text-3xl font-bold xxs:w-[90%]  xs:mt-20 xs:w-[95%] xs:text-4xl sm:w-[70%] lg:w-[85%] lg:text-5xl">
            M.Sc. (Medical Technology Anesthesia & Operation Theatre Technology)
          </div>
          <div className="mt-14 grid grid-cols-1 gap-4 text-white sm:px-10  md:px-0 lg:grid-cols-3 lg:gap-9">
            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              Duration<sup>*</sup>: 2 Years
            </div>
            {/* <div className="secondary-bg-color  h-16 items-center rounded-md p-4 text-center text-lg">
              Eligibility: B.Sc. (Microbiology, Biotechnology, Biology,
              Biochemistry, or a related discipline)
            </div> */}
            <div
              style={{
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              className="secondary-bg-color h-16 items-center rounded-md p-4 text-center text-lg"
            >
              Eligibility<sup>**</sup>:B. Sc. MT-OTT
            </div>

            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              No of seats: 30
            </div>
          </div>
          <div className=" mt-6">
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">
                DURATION<sup>*</sup>:
              </span>{" "}
              The course shall be two years, with semester system (4 semesters,
              with two semesters in a year). The Choice based credit system will
              be applicable to all the semesters.
              <br />
              <span className="font-semibold">
                ELIGIBILITY FOR ADMISSION <sup>**</sup>:
              </span>{" "}
              B. Sc. Medical Technology Anesthesia & Operation Theatre
              Technology, Candidates with 50% marks (5% relaxation for reserved
              categories) in Bachelors Degree in Anaesthesia &amp; Operation
              Theatre Technology are eligible for admission to this course.
            </div>

            <div className="text-2xl font-semibold text-[#003857]">Vision</div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <ul className="list-disc pl-4 text-justify text-lg tracking-wider">
                <li>
                  To establish &amp; develop world class self-reliant institute
                  for imparting Medical and other Health Science education at
                  under-graduate &amp; post-graduate levels of the global
                  competence.
                </li>
                <li>
                  To serve &amp; educate the public, establish guidelines &amp;
                  treatment protocols to be followed by professionals while
                  treating in hospitals.
                </li>
                <li>
                  To develop and provide professionally qualified health workers
                  for augmenting the nation’s human resources through
                  Bio-Medico-Socio-epidemiological scientific research.
                </li>
              </ul>
            </div>
            <div className="text-2xl font-semibold text-[#003857]">Mission</div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <ul className="list-disc pl-4 text-justify text-lg tracking-wider">
                <li>
                  To strive incessantly to achieve the goals of the Institution.
                </li>
                <li>
                  To impart academic excellence in Allied Health Education.
                </li>
                <li>
                  To practice medicine ethically in line with the global
                  standard protocols.
                </li>
                <li>
                  Having a revolutionary impact on students by focusing on deep
                  inter-disciplinary knowledge, getting technical as well as
                  Theoretical concept of Health Sciences, focusing on
                  leadership, communication and interpersonal skills, personal
                  health and well-being.
                </li>
                <li>
                  Creating best of educational experience by engaging with
                  partners outside the traditional borders of University campus.
                  By engaging in a network of Hospitals &amp; other Healthcare
                  providing facilities to create a job oriented
                </li>
                <li>
                  Cultivating productive community by attracting and retaining
                  diverse, best talent and such an environment where research,
                  innovation, creativity and entrepreneurship can flourish.
                </li>
                <li>
                  To give students the best knowledge by the most innovative
                  methods and also provide hospital exposure to work in
                  different fields of Paramedical Sciences.
                </li>
                <li>
                  To create a well-qualified and highly trained world class
                  Technicians &amp; Assistants who will aid in delivering
                  high-class care &amp; helping in betterment of mankind.
                </li>
              </ul>
            </div>
            <div className="text-2xl font-semibold text-[#003857]">
              Program Specific Outcomes
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PO1:</span> Have a lifelong
              knowledge of Anaesthesia, Surgery &amp; all the Equipments used in
              it along with basic knowledge of applied science.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PO2:</span> Anaesthesia &amp;
              Surgical Technicians/Assistants will work in Operation Theatres,
              ICUs etc. along with Anesthetists and Surgeons &amp; thus will be
              having a great &amp; Important role in Healthcare.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PO3:</span> After completion,
              students can go for Academics as well by joining different
              Colleges and Universities as Lecturers/Tutors.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PO4:</span> This Program will
              build technical knowledge in the student so that he/she will be
              able to assist an Anesthetist/Surgeon in every aspect of
              Anaesthesia, Surgery &amp; other related fields.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PO5:</span> Engage in lifelong
              learning and adapt to changing professional and societal needs.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PO6:</span> This Program can do an
              overall development of the student to be able to have all the
              technical aspects about Anaesthesia, Surgery along with their
              advanced knowledge.
            </div>

            <div className="">
              <div className="mt-8 text-2xl font-semibold text-[#003857]">
                Course Rationale
              </div>
              <div>
                The MSc in Anesthesia and Operation Theatre Technology typically
                aims to provide students with a comprehensive understanding of
                anesthesia techniques, operation theatre management, patient
                care, and medical equipment operation. It may cover topics such
                as anesthesia pharmacology, patient monitoring, surgical
                procedures, infection control, and emergency response protocols.
                The course also often includes practical training in anesthesia
                administration, operation theatre procedures, and equipment
                handling to prepare students for roles in healthcare settings
              </div>
            </div>

            <div className="">
              <div className="mt-8 text-2xl font-semibold text-[#003857]">
                Job Opportunities
              </div>
              <ul className="list-disc pl-4 text-justify text-[17px]">
                <li>Anesthesia Technologist</li>
                <li>Operation Theatre Technologist</li>
                <li>Surgical Assistant</li>
                <li>Healthcare Administrator</li>
                <li>Clinical Educator</li>
                <li>Medical Equipment Sales Representative</li>
                <li>Research Associate</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PGRadiology = () => {
  return (
    <div>
      <div className="xs:px-4">
        <div className="md:px-[24px] lg:px-0">
          <div className="primary-color container mt-8 ml-0 w-72  border-l-4 border-[#32727a]  pl-3 text-3xl font-bold xxs:w-[90%]  xs:mt-20 xs:w-[95%] xs:text-4xl sm:w-[70%] lg:w-[85%] lg:text-5xl">
            M.Sc. (Radiology Imaging Technology)
          </div>
          <div className="mt-14 grid grid-cols-1 gap-4 text-white sm:px-10  md:px-0 lg:grid-cols-3 lg:gap-9">
            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              Duration: 2 Years
            </div>
            {/* <div className="secondary-bg-color  h-16 items-center rounded-md p-4 text-center text-lg">
              Eligibility: B.Sc. (Microbiology, Biotechnology, Biology,
              Biochemistry, or a related discipline)
            </div> */}
            <div
              style={{
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              className="secondary-bg-color h-16 items-center rounded-md p-4 text-center text-lg"
            >
              Eligibility: B. Sc. RIT
            </div>

            <div className="secondary-bg-color  h-16 rounded-md p-4 text-center text-lg">
              No of seats: 30
            </div>
          </div>
          <div className=" mt-6">
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className=" font-semibold">Eligibility: </span> B. Sc.
              Radiology Imaging Technology
            </div>
            <div className="text-2xl font-semibold text-[#003857]">Vision</div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <ul className="list-disc pl-4 text-justify text-lg tracking-wider">
                <li>
                  To impart knowledge of health & medical education & help in
                  making India a centre of Medical Education & Health Care.
                </li>
                <li>
                  To establish & develop world class self-reliant institute for
                  imparting Medical and other Health Science education at
                  under-graduate & post-graduate levels of the global
                  competence.
                </li>
                <li>
                  To serve & educate the public, establish guidelines &
                  treatment protocols to be followed by professionals while
                  treating in hospitals.
                </li>
                <li>
                  To develop and provide professionally qualified health workers
                  for augmenting the nation’s human resources through
                  Bio-Medico-Socio-epidemiological scientific research.
                </li>
              </ul>
            </div>
            <div className="text-2xl font-semibold text-[#003857]">Mission</div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <ul className="list-disc pl-4 text-justify text-lg tracking-wider">
                <li>
                  To strive incessantly to achieve the goals of the Institution.
                </li>
                <li>
                  To impart academic excellence in Allied Health Education.
                </li>
                <li>
                  To practice medicine ethically in line with the global
                  standard protocols.
                </li>
                <li>
                  Having a revolutionary impact on students by focusing on deep
                  inter-disciplinary knowledge, getting technical as well as
                  Theoretical concept of Health Sciences, focusing on
                  leadership, communication and interpersonal skills, personal
                  health and well-being.
                </li>
                <li>
                  Creating the best educational experience by engaging with
                  partners outside the traditional borders of University campus.
                  By engaging in a network of Hospitals & other Healthcare
                  providing facilities to create a job oriented
                </li>
                <li>
                  Cultivating a productive community by attracting and retaining
                  diverse, best talent and such an environment where research,
                  innovation, creativity and entrepreneurship can flourish.
                </li>
                <li>
                  To give students the best knowledge by the most innovative
                  methods and also provide hospital exposure to work in
                  different fields of Paramedical Sciences.
                </li>
                <li>
                  To create a well-qualified and highly trained world-class
                  Technicians & Assistants who will aid in delivering high-class
                  care & helping in the betterment of mankind.
                </li>
              </ul>
            </div>
            <div className="text-2xl font-semibold text-[#003857]">
              Program Specific Outcomes
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PO1:</span> On completion of the
              program, Technologists can advance to supervisory position in
              Diagnostic Centers and hospitals.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PO2:</span> They can also earn key
              posts in academic institutions including teaching and research.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PO3:</span> In the industry,
              Imaging technologists are needed for Application and Software
              development for Medical Imaging equipment.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PO4:</span> This Program will
              build technical knowledge in the student so that he/she will be
              able to assist an Anesthetist/Surgeon in every aspect of
              Anaesthesia, Surgery & other related fields.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PO5:</span> Engage in lifelong
              learning and adapt to changing professional and societal needs.
            </div>
            <div className="mt-6 break-words text-justify text-lg tracking-wider">
              <span className="font-semibold">PO6:</span> The Candidates can
              join Private, Military and public health services.
            </div>

            <div className="">
              <div className="mt-8 text-2xl font-semibold text-[#003857]">
                Course Rationale
              </div>
              <div className=" mt-1 break-words text-justify text-lg tracking-wider">
                The rationale behind an MSc in Radiation and Imaging Technology
                lies in the increasing demand for skilled professionals in the
                field of medical imaging, radiation therapy, and radiation
                safety. This program typically aims to provide students with
                advanced knowledge and practical skills in areas such as
                diagnostic imaging techniques, radiation therapy planning and
                delivery, radiation safety protocols, and emerging technologies
                in medical imaging. By offering specialized training in these
                areas, the program prepares graduates to contribute to the
                healthcare industry by ensuring accurate diagnoses, effective
                treatment planning, and patient safety in the context of medical
                radiation applications. Additionally, with advancements in
                imaging technology and increased emphasis on precision medicine,
                there is a growing need for professionals who can leverage these
                technologies effectively to improve patient outcomes. Thus, an
                MSc in Radiation and Imaging Technology serves to fulfill this
                demand by equipping students with the necessary expertise to
                excel in this specialized field.
              </div>
            </div>
            <div className="">
              <div className="mt-8 text-2xl font-semibold text-[#003857]">
                CAREER OPPORTUNITIES
              </div>
              <ul className="list-disc pl-4 text-justify text-[17px]">
                <li>Radiologic Technologist</li>
                <li>MRI Technologist</li>
                <li>CT Technologist</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
